import React from "react";
import "./Stars.css";

function Stars() {
  const stars = Array.from({ length: 200 }, (_, i) => i); // Create 200 stars
  const planets = Array.from({ length: 9 }, (_, i) => i); // Create 9 planets

  return (
    <div className="galaxy">
      {stars.map((i) => (
        <div
          key={i}
          className="stars"
          style={{
            top: `${Math.random() * 100}vh`,
            left: `${Math.random() * 100}vw`,
            animationDuration: `${2 + Math.random() * 3}s`,
            animationDelay: `${Math.random() * 2}s`,
          }}
        />
      ))}
      {planets.map((i) => (
        <div
          key={i}
          className="planet"
          style={{
            top: `${Math.random() * 100}vh`,
            left: `${Math.random() * 100}vw`,
            animationDuration: `${10 + Math.random() * 20}s`,
            animationDelay: `${Math.random() * 5}s`,
            transformOrigin: `${Math.random() * 100}% ${Math.random() * 100}%`,
          }}
        />
      ))}
    </div>
  );
}

export default Stars;
